<template>
  <div v-if="shops">
    <CRow>
      <CCol md="12">
        <CCard class="shadow-sm" v-if="shops.length === 0" md="12" lg="12">
          <CCardBody>
            <CRow>
              <CCol sm="12" lg="12">
                <a
                  href="https://apps.apple.com/th/app/silom-pos-point-of-sale/id1230301725?l=th"
                >
                  <img alt="" src="/img/shop.jpg" class="img-fluid" />
                </a>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CRow v-else>
          <CCol md="6" v-for="shop in shops" :key="shop.objectId">
            <div class="card" style="border-radius:15px">
              <div class="text-center">
                <img
                  v-if="shop.remoteImagePath === undefined"
                  class="card-img-top img-fluid mt-3"
                  style="width:40%"
                  src="https://cdn-icons-png.flaticon.com/512/4472/4472453.png"
                  onerror="this.onerror=null;this.src='https://cdn-icons-png.flaticon.com/512/4472/4472453.png';"
                />

                <img
                  v-else
                  class="card-img-top img-fluid mt-3"
                  style="width:40%"
                  :src="shop.remoteImagePath"
                  onerror="this.onerror=null;this.src='https://cdn-icons-png.flaticon.com/512/4472/4472453.png';"
                />
                <h4 class="font-weight-normal text-black mt-3">{{ shopName }}</h4>
                <h5 class="text-center text-success font-weight-normal">
                  {{ shop.branchName }}
                </h5>
              </div>

              <div class="card-body">
                <hr />
                <table style="width:100%;" aria-describedby="">
                  <tr class="text-left">
                    <th scope="col" class="text-dark font-weight-normal" valign="top">
                      {{ $t('businessType') }}
                    </th>
                    <td
                      v-if="shop.businessType === 1"
                      class="text-right text-description"
                    >
                      <p>{{ $t('businessType1') }}</p>
                    </td>
                    <td
                      v-if="shop.businessType === 2"
                      class="text-right text-description"
                    >
                      <p>{{ $t('businessType2') }}</p>
                    </td>
                    <td
                      v-if="shop.businessType === 3"
                      class="text-right text-description"
                    >
                      <p>{{ $t('businessType3') }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style=""
                      class="font-weight-normal text-left text-dark"
                      valign="top"
                    >
                      {{ $t('taxID') }}
                    </td>
                    <td class="text-right text-description">
                      <p>{{ shop.taxId || '-' }}</p>
                    </td>
                  </tr>

                  <tr>
                    <td
                      class="font-weight-normal text-left text-dark"
                      valign="top"
                    >
                    {{ $t('address') }}
                    </td>
                    <td class="text-right text-description">
                      <p>{{ shop.address1 }} {{ shop.address2 }}</p>
                    </td>
                  </tr>

                  <tr>
                    <td
                      class="font-weight-normal text-left text-dark"
                      valign="top"
                    >
                    {{ $t('tel') }}
                    </td>
                    <td class="text-right text-description">
                      <p>{{ shop.tel }}</p>
                    </td>
                  </tr>

                  <tr>
                    <td
                      class="font-weight-normal text-left text-dark"
                      valign="top"
                    >
                      {{ $t('open') }}
                    </td>
                    <td class="text-right text-description">
                      <p>{{ shop.open }} - {{ shop.close }}</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NoShop from '@/containers/NoShop.vue'

export default {
  components: { NoShop },
  computed: {
    ...mapGetters(['shops', 'users']),
    shopName() {
      if (this.shops.length !== 0) {
        return this.shops[0].shopName
      }
    },
  },
}
</script>
